<template>
    <div>
        <div class="header">
            <span class="header_txt">用户列表</span>
        </div>
        <div class="header_box">
            <div class="left">
                <span class="left_span">门店列表：</span>
                <Select v-model="storePrams.shop_id" style="width:200px;margin-right: 20px;" @on-open-change="shopSelectFun">
                    <Option v-for="item in customList" :value="item.id" :key="item.id">{{ item.name }}</Option>
                </Select>
                <span class="left_span">顾客信息：</span>
                <Input v-model="storePrams.keywords" type="text" placeholder="顾客手机号" style="width:200px;margin-right: 20px;"/>
                <span class="left_span">直属员工：</span>
                <Input v-model="storePrams.phone" type="text" placeholder="员工手机号" style="width:200px;margin-right: 20px;"/>
                <button class="header_btn" @click="search" style="width:70px">搜索</button>
            </div>
            <div class="right"></div>
        </div>
        <div class="content">
            <Table :columns="liveTable" :data="dataTable" border>
                <template slot-scope="{ row, index }" slot="nickname">
                    <div style="display: flex;">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:space-between;width: 90%;">
                            <img src="@/assets/custom.jpg" alt="" style="width: 50px;border-radius:5px;">
                            <img v-if="row.portrait" :src="row.portrait" alt="" style="width: 50px;border-radius:5px;">
                            <div style="display:flex;flex-direction:column;align-items:center;">
                                <span>{{row.nickname}}</span>
                                <span>{{row.phone}}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template slot-scope="{row}" slot="shop">
                    <span v-if="row.shop">{{row.shop.name}}</span>
                </template>
                <template slot-scope="{ row, index }" slot="staff">
                    <div style="display: flex;">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:space-between;width: 90%;">
                            <img src="@/assets/head_man.png" alt="" style="width: 50px;border-radius:5px;">
                            <div style="display:flex;flex-direction:column;align-items:center;">
                                <span v-if="row.staff">{{row.staff.name}}</span>
                                <span v-if="row.staff">{{row.staff.phone}}</span>
                                <span v-if="row.shop">{{row.shop.name}}</span>
                            </div>
                        </div>
                    </div>
                </template>
                <template slot-scope="{row}" slot="is_prohibition">
                    <span class="infoModal_text" @click="change(row)">变更上级</span>
                    <span v-if="row.is_prohibition == 1" class="infoModal_text" style="color:#f00;" @click="prohibition(row)">解除禁言</span>
                    <span v-if="row.is_prohibition == 0" class="infoModal_text" style="color:#f00;" @click="prohibition(row)">禁言</span>
                </template>
            </Table>
        </div>
        <div class="footer">
            <Page :total="total" show-elevator show-total @on-change="pageChange"/>
        </div>
        <!-- 变更上级弹框 -->
        <Modal v-model="changeModal" width="50%">
            <div slot="header"></div> 
            <div>
                <div style="display:flex;flex-direction:row;">
                    <Input v-model="storePramsModal.keywords" type="text" placeholder="请输入姓名/手机号" style="width:100%;"/>
                    <button class="header_btn" @click="searchModal(storePramsModal.keywords)" style="width:70px">搜索</button>
                </div>
                <Table :columns="changeTable" :data="changeDataTable" @on-select="selectOne"></Table>
            </div>
            <div class="footer">
                <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
            </div>
            <div slot="footer">
                <Button @click="changeModalF">取消</Button>
                <Button type="primary" @click="changeModalT">确认</Button>
            </div>
            <div slot="close"></div>
        </Modal>
    </div>
</template>

<script>
import {customerList, shopSelect, customerChangeUp, staffSelect, customerProh} from '@/api/index'
    export default {
        data() {
            return {
                customList: [],
                //table
                liveTable: [
                    {title: '客户',slot: 'nickname'},
                    {title: '客户店铺',slot: 'shop',align:'center'},
                    {title: '注册时间',key: 'created_at',align:'center'},
                    {title: '直属员工',slot: 'staff',align:'center'},
                    {title: '操作',slot: 'is_prohibition',align:'center'}
                ],
                dataTable: [],
                //page
                total: 1,
                storePrams: {
                    page: 1,
                    limit: 30,
                    keywords:'',
                    shop_id:'-1',
                    phone:''
                },
                changeModal:false,
                changeTable: [
                    {title: '选择',type:'selection',width:'65'},
                    {title: '姓名',key: 'name',width:'120'},
                    {title: '手机号',key: 'phone',align:'center'}
                ],
                changeDataTable: [],
                totalModal: 10,
                storePramsModal: {
                    page: 1,
                    limit: 30,
                    keywords:''
                },
                changeList:{
                    staff_id:'',
                    id:''
                }
            }
        },
        mounted(){
            this.customerList()
            this.staffSelect()
        },
        methods: {
            selectOne(selection, row) {
                //当多选的时候有变化的时候调用该方法
                // this.selectall = false;
                // this.SelectedDatas = selection;
                this.changeList.staff_id = row.id
            },
            // 员工列表
            staffSelect(){
                staffSelect(this.storePramsModal).then(res => {
                    console.log('员工列表',res.data.data)
                    this.totalModal = res.data.total
                    this.changeDataTable = res.data.data
                })
            },
            //下拉店铺数据 
            shopSelectFun(){
                shopSelect().then(res => {
                    this.customList = res.data
                })
            },
            // 客户列表
            customerList(){
                customerList(this.storePrams).then(res => {
                    console.log('客户列表',res.data.data)
                    this.total = res.data.total
                    this.dataTable = res.data.data
                })
            },
            search(){
                this.customerList()            
            },
            pageChange(index) {
                this.storePrams.page = index;
                this.customerList()
            },
            change(item){
                this.changeList.id = item.id
                this.staffSelect()
                this.changeModal = true
            },
            pageChangeModal(index) {
                this.storePramsModal.page = index;
            },
            changeModalF(){
                this.changeModal = false
            },
            changeModalT(){
                // 变更上级
                let data = {
                    staff_id: this.changeList.staff_id,
                    id: this.changeList.id
                }
                customerChangeUp(data).then(res => {
                    this.changeModal = false
                    this.customerList()
                    this.$Message.success(res.msg);
                })
                .catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
            searchModal(e){
                this.storePrams.keywords = e
                this.staffSelect()
            },
            //点击禁言触发
            prohibition(item){
                if(item.is_prohibition == 1){
                    item.is_prohibition = 0
                }else if(item.is_prohibition == 0){
                    item.is_prohibition = 1
                }
                let data = {
                    is_prohibition: item.is_prohibition,
                    id: item.id
                }
                customerProh(data).then(res => {
                    this.customerList()
                    this.$Message.success(res.msg);
                })
                .catch((res) => {
                    this.$Message.error(res.msg);
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.header_btn{
    width: 100px;
    padding: 10px;
    background-color: #409eff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.header_box{
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 40px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}
.left{
    display: flex;
    align-items: center;
}
.content{
}
.footer{
    margin: 20px 0;
    text-align: center;
}
.left_span{
    height: 21px;
    color: #374a70;
    font-weight: 700;
    font-size: 16px;
    margin-right: 4px;
    margin-left: 2px;
}
::v-deep .ivu-select-selection{
    height: 40px;
}
::v-deep .ivu-select-selected-value{
    height: 40px !important;
    line-height: 40px !important;
}
::v-deep .ivu-input.ivu-input-default{
    height: 40px;
}
::v-deep .ivu-select-placeholder{
    height: 40px !important;
    line-height: 40px !important;
}
.infoModal_text{
    color: #409eff;
    margin: 10px;
    font-size: 12px;
    cursor: pointer;
}
</style>